<template>
	<dialog-common ref="dialog" :title="option.title" @ok="confirm">

		<div class="content">
			<div class="item clickable" v-bind:class="{'item-selected':selection==index}"
				v-for="(item,index) in option.items"
				@click="selection = index">{{item}}</div>
			<div class="clear"></div>
		</div>


	</dialog-common>
</template>

<script>
	import DialogCommon from "./dialog-common.vue"
	export default {
		components:{DialogCommon},
		data(){
			return {
				selection:"",
				option:{
					title:"",
					value:"",
					items:[],
					onConfirm:null
				}
			}
		},
		methods:{
			open(option){
				this.option = option
				this.selection = option.value
				this.$refs.dialog.open()
			},
			close(){
				this.$refs.dialog.close()
			},
			confirm(){
				this.option.onConfirm(this.selection)
			},

		}
	}
</script>

<style lang="scss" scoped>
	.content{
	}

	.item{
		border: 1px solid #ddd;
		padding: 8px;
		text-align: center;
		margin: 10px;
		color: #999;
		font-size: 14px;
		background: #F5F5F5;
	}

	.item-selected{
		color: #FFF;
		background: #2ca338;
		border: 1px solid #2ca338;
	}


</style>
